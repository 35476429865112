
export default {
    name: "EqualGridBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        cols() {
            return Number(this.blok.cols ?? 2)
        },
        colsMobile() {
            if (!this.blok.colsMobile) return
            return Number(this.blok.colsMobile)
        },
        gap() {
            return Number(this.blok.gap ?? 12)
        },
    },
}
